.inputPass {
    border: none;
    font-size: 16px;
    border-radius: 3px;
    width: 250px;
    height: 52px;
    background: #fff;
    margin-left: 10px;
    width: 200px;
    height: 40px;
    padding: 4px 15px;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 700;
}

.inputPass__box {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
}

h1 {
    color: #fff;
    font-weight: 700;
}

.button__box {
    display: flex;
    justify-content: flex-end;
}

button {
    border: 1px solid;
    border-color: #fff;
    background: #fff;
    font-size: 16px;
    font-family: inherit;
    margin-right: 2px;
    margin-top: 5px;
    padding: 7px 15px;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 700;
    max-width: 180px;
}

p {
    /* margin: 0 auto; */
    margin-top: 20px;    
}

.inputIp {
    border: none;
    font-size: 16px;
    border-radius: 3px;
    width: 250px;
    height: 52px;
    background: #fff;
    margin-left: 10px;
    width: 200px;
    height: 40px;
    padding: 4px 15px;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 700;
}

input:hover {
    display: block;
    background: url('../../assets/copy.png') no-repeat right;
    background-position: 90% 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: #fff;
}

.inputIp__box {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
}

p {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}

.aviso {
    margin: 0 auto;
    margin-top: 10px;
}