@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
    font-family: 'Roboto';
}

.newgerador {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(228, 92, 87);
    padding: 20px;
    border: 2px solid #000;
    border-radius: 20px;
    max-width: 440px;;
}

.newgerador__box {
    display: flex;
    flex-direction: column;
    width: 100%;
}

input {
    margin: 10px 0;
}